import React, { useState } from 'react';
import DashboardIcon from '@mui/icons-material/Dashboard';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import DesktopMacIcon from '@mui/icons-material/DesktopMac';
import ListIcon from '@mui/icons-material/List';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import SettingsIcon from '@mui/icons-material/Settings';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import './sidebar.css';
// import { ReactComponent as ScreenIcon } from "../../Assets/images/screenIcon.svg";
// import { ReactComponent as LogoutIcon } from "../../Assets/images/logoutIcon.svg";
import { logout } from '../../Redux/Actions/userAction';
import {useDispatch} from 'react-redux';
import { Button } from '@mui/material';
import {makeStyles} from '@material-ui/core';
import { LogoutOutlined } from '@mui/icons-material';
import { AiFillAndroid } from 'react-icons/ai';
const useStyle=makeStyles((theme)=>{
    return {
      text:{
          [theme.breakpoints.down('sm')]:{
            //   display:'none'
          }
      },
      sibarContainerMobile:{
        [theme.breakpoints.down('sm')]:{
            // width:'70px !important',
        }
    },
    sidebarUpperSecMobile:{
       
        [theme.breakpoints.down('sm')]:{
            display:'flex !important',
            justifyContent:'center !important',
            alignItems:'center !important',
            // width:'70px !important',
            // height:'20vh',
            '& img':{
                // width:'80px !important',
                // height:'80px !important'
            }
        }
    }
    }
})
function Sidebar({ user }) {
    const location=useLocation();
    const classes=useStyle();
    const [gridView, setGridView] = useState(true)
    const dispatch=useDispatch();
    const navigate=useNavigate();
const logoutHandler=()=>{
    dispatch(logout())
 return navigate('/login')
}
// console.log(location.pathname)
    return (
        <>
            <div className={`sidebar-container ${classes.sibarContainerMobile}`}>
                <div className={`sidebar-upper-sec ${classes.sidebarUpperSecMobile}`}>
                    <img src='/images/lexi-logo.jpeg' style={{height:'100%',width:'100%'}} />
                </div>
                <div className="sidebar-middle-sec">
                    <div className="side-bar-box">
                        <div className="links" style={{background:location.pathname==='/' ? "var(--primary-color-rgba)" : ''}}>
                            <div className='link-icon'>
                                <DashboardIcon className={location.pathname=='/' ? 'link-active-a-color':'link-a-color'} />
                            </div>
                            <Link
                            style={{color:location.pathname==='/' ? "var(--primary-forecolor)" : ''}}
                             to='/'>Dashboard</Link>
                        </div>

                        <div className="links" style={{background:location.pathname==='/content' ? "var(--primary-color-rgba)" : ''}}>
                            <div className='link-icon'>
                                <TextSnippetIcon className={location.pathname=='/content' ? 'link-active-a-color':'link-a-color'} />
                            </div>
                            <Link style={{
                                color:location.pathname==='/content' ? "var(--primary-forecolor)" : '',
                            }}  to="/content">Content</Link>
                        </div>
                        {
                            user.role === 'admin' &&
                            <>
                            <div className="links" style={{background:location.pathname==='/user' ? "var(--primary-color-rgba)" : ''}}>
                                <div className='link-icon'>
                                    <PersonAddIcon className={location.pathname=='/user' ? 'link-active-a-color':'link-a-color'} />
                                </div>
                                <Link  
                                style={{color:location.pathname==='/user' ? "var(--primary-forecolor)" : ''}}
                                 to="/user">User</Link>
                            </div>
                            <div className="links" style={{background:location.pathname==='/apk' ? "var(--primary-color-rgba)" : ''}}>
                                <div className='link-icon'>
                                    <AiFillAndroid size={'lg'} className={location.pathname=='/apk' ? 'link-active-a-color':'link-a-color'} />
                                </div>
                                <Link  
                                style={{color:location.pathname==='/apk' ? "var(--primary-forecolor)" : ''}}
                                 to="/apk">Apk</Link>
                            </div>
                            </>
                        }
                        <div className="links" style={{background:location.pathname==='/screen' ? "var(--primary-color-rgba)" : ''}}>
                            <div className='link-icon'>
                                <DesktopMacIcon className={location.pathname=='/screen' ? 'link-active-a-color':'link-a-color'} />
                            </div>
                            <Link  
                            style={{color:location.pathname==='/screen' ? "var(--primary-forecolor)" : ''}}
                             to="/screen">Screen</Link>
                        </div>

                        <div className="links" style={{background:location.pathname==='/playlist' ? "var(--primary-color-rgba)" : ''}}>
                            <div className='link-icon'>
                                <ListIcon className={location.pathname=='/playlist' ? 'link-active-a-color':'link-a-color'} />
                            </div>
                            <Link  
                            style={{color:location.pathname==='/playlist' ? "var(--primary-forecolor)" : ''}}
                             to="/playlist">Playlist</Link>
                        </div>

                        <div className="links">
                            <div className='link-icon'>
                                <LogoutOutlined className={'link-a-color'} />
                            </div>
                            <button style={{
                                border:'none',
                                outline:'none',
                                background: (!gridView ? '#F3F0FF' : 'white'),
                                cursor:'pointer',
                                color: '#B8B8B8'

                            }} onClick={logoutHandler}>Logout</button>
                        </div>
                    </div>
                </div>
                <div className="sidebar-bottom-sec">
                    <div className="settings">
                        <SettingsIcon style={{ color: 'white' }} />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Sidebar