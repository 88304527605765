import {
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Input,
  TextField,
  FormControl,
  ListItemIcon,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import DonutChart from "../../components/charts/DonutChart";
import Layout from "../../components/layout/Layout";
import {
  addPlaylistToScreen,
  screenFlagOff,
  uncheckAssignedScreen,
} from "../../Redux/Actions/screenAction";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import "./setting.css";
import { ReactComponent as ScreenIcon } from "../../Assets/images/screenIcon.svg";
import { ReactComponent as UserProfileIcon } from "../../Assets/images/userprofileIcon.svg";
import { ReactComponent as MobileImage } from "../../Assets/images/mobileImage.svg";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import MobileFriendlyIcon from "@mui/icons-material/MobileFriendly";
import { TextareaAutosize } from "@material-ui/core";
import { playlistFlagOff } from "../../Redux/Actions/playlistAction";
import { DesktopMac } from "@mui/icons-material";
import ListIcon from "@mui/icons-material/List";
import toast from "react-hot-toast";
function Setting({ user, socketRef }) {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const { screens, isUnChecked,apk } = useSelector((state) => ({
    screens:state.Screen.screens,
    isUnChecked:state.Screen.isUnChecked,
    apk:state.Apk.apk
  }),shallowEqual);
  const { playlist } = useSelector((state) => state.Playlist);
  const [playlistInfo, setPlaylistInfo] = useState({});
  const [code, setCode] = useState("");
  const [openModel, setOpenModel] = useState(false);
  const [message, setMessage] = useState("");
  const [duration, setDuration] = useState(60);
  const [instantEvent, setInstantEvent] = useState("");
  const [apkDetail,setApkDetail]=useState({});
  const [isApkUpdated,setIsApkUpdated]=useState(false);
  useEffect(() => {
    setPlaylistInfo({
      ...screens.find((s) => s._id === params.id),
      screenAssigned: false,
    });
  }, [params.id]);
  useEffect(() => {
    if (isUnChecked) {
      setPlaylistInfo({ ...screens.find((s) => s._id === params.id) });
      dispatch(screenFlagOff());
    }
  }, [isUnChecked]);
  useEffect(() => {
    socketRef.current.emit("leaving--connection");
    socketRef.current.on("screen--connection--off", (obj) => {
      //  console.log(obj)
    });
  }, []);

  useEffect(()=>{
    const s=screens.find(s=>s._id===params.id);
     apk.forEach(a=>{
       if(a?.versionName > s?.device_details?.software){
        setApkDetail({url:`${a.url}`});
        setIsApkUpdated(true);
       }
     })
    //  setScreenInfo({...s});
     console.log(s)
  },[params.id])
  const sendApkUpdate=()=>{
    socketRef.current.emit('sendapkupdate',{url:apkDetail.url,mac:playlistInfo.mac},()=>toast.success('Apk updating...'));
  }

  const submit = () => {
    socketRef?.current.emit("message", {
      message,
      duration,
      mac: playlistInfo.mac,
    });
    setOpenModel(false);
    setMessage("");
  };
  const emitAssignPlaylistToScreen = (c) => {
    const id = setTimeout(() => {
      const screen = screens.find((s) => s._id === c);
      // console.log(screen);
      if (screen !== null) {
        socketRef.current?.emit("event:delegate", {
          detail: { mac: screen?.mac, code: c },
        });
        clearTimeout(id);
      }
    }, 2000);
  };
  const screenHanlderSelect = (e) => {
    setCode(e.target.value);
    const mac = screens.find((c) => c._id === params.id);
    // console.log(e.target.value);
    dispatch(
      addPlaylistToScreen({
        id: params.id,
        value: e.target.value,
        mac: mac.mac,
      })
    );
    // setPlaylistInfo({...screens.find(s=>s._id===params.id),screenAssigned:true})
    emitAssignPlaylistToScreen(params.id);
  };
  return (
    <>
      {/* {
      console.log(playlistInfo)
    } */}
      <Layout title={"Setting"} user={user}>
        <Grid container>
          <Grid item lg={8} xs={12}>
            <Box
              sx={{
                background: "white",
                p: 2,
                margin: "18px 20px",
                borderRadius: "16px",
              }}
            >
              <div className="device flex flex-column">
                <div className="device-header flex flex-content-space-around alignItemCenter">
                  <div className="device-header-left flex">
                    <div className="screen-icon flex justifyContentCenter alignItemCenter">
                      <MobileFriendlyIcon
                        style={{ color: "var(--primary-color)" }}
                      />
                      {/* <img src={"/images/MobileScreen.png"} /> */}
                    </div>
                    <div className="screen-text flex flex-column">
                      <p>{playlistInfo?.device_details?.device_name}</p>
                      {playlistInfo?.onlinesStatus ? (
                        <span>Connected</span>
                      ) : (
                        <span>Disconnected</span>
                      )}
                    </div>
                  </div>
                  <div className="device-header-right flex">
                    <div className="playlist-selector flex flex-column justifyContentCenter alignItemCenter">
                      {/* {playlistInfo?.playlist === undefined ||
                      playlistInfo?.screenAssigned ? (
                        <select
                          className="deviceSelect"
                          value={code}
                          onChange={screenHanlderSelect}
                          style={{
                            height: "30px",
                            width: "200px",
                            margin: "0 20px",
                            background: "rgba(217, 217, 217, 0.3)",
                            border: "1px solid rgba(85, 31, 255, 0.1)",
                            color: "black",
                            borderRadius: "5px",
                          }}
                        >
                          <option>select Device</option>
                          {playlist &&
                            playlist.map((play) => (
                              <>
                                <option value={play._id}>{play.name}</option>
                              </>
                            ))}
                        </select>
                      ) : ( */}
                      <Box
                        sx={{
                          display: "flex",
                          gap: "5px",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <ListIcon
                          style={{ color: "var(--primary-color)" }}
                          // onClick={() =>
                          //   dispatch(uncheckAssignedScreen(playlistInfo._id))
                          // }
                        />
                        <p>{playlistInfo?.playlist?.name}</p>
                        {/* <BorderColorIcon
                            style={{ color: "var(--primary-color)" }}
                            onClick={() =>
                              dispatch(uncheckAssignedScreen(playlistInfo._id))
                            }
                          /> */}
                      </Box>
                      {/* )} */}
                    </div>
                  </div>
                </div>
                <Grid container>
                  <Grid item lg={8}>
                    <Grid
                      container
                      sx={{
                        borderBottom: "1px solid rgba(85, 31, 255, 0.2)",
                        pt: 2.5,
                        pb: 2.5,
                      }}
                    >
                      <Grid item lg={6}>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            pl: 7,
                          }}
                        >
                          <div className="info-1">
                            <p>Code</p>
                            <span>{playlistInfo.code}</span>
                          </div>
                          <div className="info-2">
                            <p>Device Name</p>
                            <span>
                              {playlistInfo?.device_details?.device_name}
                            </span>
                          </div>
                        </Box>
                      </Grid>
                      <Grid item lg={6}>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            pl: 7,
                          }}
                        >
                          <div className="info-1">
                            <p>Name</p>
                            <span>{playlistInfo.name}</span>
                          </div>
                          <div className="info-2">
                            <p>Manufacturing</p>
                            <span>
                              {playlistInfo?.device_details?.manufacturer}
                            </span>
                          </div>
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      sx={{
                        borderBottom: "1px solid rgba(85, 31, 255, 0.2)",
                        pt: 2.5,
                        pb: 2.5,
                      }}
                    >
                      <Grid item lg={6}>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            ml: 7,
                          }}
                        >
                          <div className="info-1">
                            <p>OS</p>
                            <span>
                              {playlistInfo?.device_details?.os_version}
                            </span>
                          </div>
                          <div className="info-2">
                            <p>Location</p>
                            <span>United State</span>
                          </div>
                        </Box>
                      </Grid>
                      <Grid item lg={6}>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            ml: 7,
                          }}
                        >
                          <div className="info-1">
                            <p>Mac Address</p>
                            <span>{playlistInfo.mac}</span>
                          </div>
                          <div className="info-2">
                            <p>Ip Address</p>
                            <span>{playlistInfo?.device_details?.ip}</span>
                          </div>
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      sx={{
                        pt: 2.5,
                        pb: 2.5,
                      }}
                    >
                      <Grid item lg={6}>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            ml: 7,
                          }}
                        >
                          <div className="info-1">
                            <p>Software</p>
                            <span>
                              {playlistInfo?.device_details?.software}
                            </span>
                          </div>
                        </Box>
                      </Grid>
                      <Grid item lg={6}>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            ml: 7,
                          }}
                        >
                          <div className="info-1">
                            <p>Root Level</p>
                            <span>
                              {playlistInfo?.device_details?.root_level}
                            </span>
                          </div>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item lg={4}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        color: "#15192C",
                        fontSize: "18px",
                        py: 3,
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: "bold",
                        }}
                      >
                        Device Details
                      </Typography>
                    </Box>
                    <Box>
                      <div className="ram">
                        <DonutChart
                          views={"Ram"}
                          series={[
                            +playlistInfo?.ram?.total.split(" ")[0] -
                              +playlistInfo?.ram?.free.split(" ")[0],
                            +playlistInfo?.ram?.free.split(" ")[0],
                          ]}
                          labels={[
                            `Usage ${playlistInfo?.ram?.total.split(" ")[1]}`,
                            `Free ${playlistInfo?.ram?.free.split(" ")[1]}`,
                          ]}
                        />
                      </div>
                    </Box>
                    <Box>
                      <div className="storage">
                        <DonutChart
                          views={"Storage"}
                          series={[
                            +playlistInfo?.storage?.total.split(" ")[0] -
                              +playlistInfo?.storage?.free.split(" ")[0],
                            +playlistInfo?.storage?.free.split(" ")[0],
                          ]}
                          labels={[
                            `Usage ${
                              playlistInfo?.storage?.total.split(" ")[1]
                            }`,
                            `Free ${playlistInfo?.storage?.free.split(" ")[1]}`,
                          ]}
                        />
                      </div>
                    </Box>
                  </Grid>
                </Grid>
              </div>
            </Box>
          </Grid>
          <Grid item lg={4} xs={12}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                // background: "var(--primary-color)",
                background: "white",
                padding: "15px 20px",
                color: "white",
                margin: "15px",
                borderRadius: "15px",
              }}
            >
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Seect Action
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={instantEvent}
                  onChange={(e) => setInstantEvent(e.target.value)}
                  variant={"outlined"}
                  label="Select Action"
                  sx={{
                    border: "1px solid white",
                    width: "100%",
                    margin: "0 auto",
                  }}
                >
                  <MenuItem
                    value={"Close App"}
                    onClick={() =>
                      socketRef.current.emit("close--app", {
                        mac: playlistInfo?.mac,
                      },()=>toast.success('App closed successfully.'))
                    }
                  >
                    Close App
                  </MenuItem>
                  <MenuItem
                    value={"Launch App"}
                    onClick={() =>
                      socketRef.current.emit("launch--app", {
                        mac: playlistInfo?.mac,
                      },()=>toast.success('App launched successfully.'))
                    }
                  >
                    Lauch App
                  </MenuItem>
                  <MenuItem
                    value={"Reset App"}
                    onClick={() =>
                      socketRef.current.emit("reset--app", {
                        mac: playlistInfo?.mac,
                      },()=>toast.success('App reset successfully.'))
                    }
                  >
                    Reset App
                  </MenuItem>
                  <MenuItem
                    value={"Reload App"}
                    onClick={() =>
                      socketRef.current.emit("reload--app", {
                        mac: playlistInfo?.mac,
                      },()=>toast.success('App reloaded successfully.'))
                    }
                  >
                    Reload App
                  </MenuItem>
                  <MenuItem
                    value={"Refresh App"}
                    onClick={() =>
                      socketRef.current.emit("refresh--app", {
                        mac: playlistInfo?.mac,
                      },()=>toast.success('App refresh successfully.'))
                    }
                  >
                    Refresh App
                  </MenuItem>
                </Select>
              </FormControl>
              {/* <UserProfileIcon />
              <Typography
                sx={{
                  fontSize: "12px",
                  pl: 1,
                }}
              >
                User profile settings
              </Typography> */}
            </Box>
            <Box
              sx={{
                background: "white",
                p: 2,
                margin: "18px 20px",
                borderRadius: "16px",
              }}
            >
              <MobileImage />
              <Box>
                <Typography
                  sx={{
                    color: "#15192C",
                    fontSize: "16px",
                    fontWeight: "bold",
                    mt: 3,
                  }}
                >
                  Detail:
                </Typography>
                <Typography
                  sx={{
                    color: "#92959E",
                    fontSize: "15px",
                  }}
                >
                  Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum
                  Lorem Ipsum Lorem Ipsum Lorem Ipsum
                </Typography>
                <Button
                  variant={"outlined"}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "15px 20px",
                    color: "var(--primary-color)",
                    margin: "15px",
                    borderRadius: "6px",
                    border: " 1px solid var(--primary-color)",
                    mt: 3,
                    fontSize: "15px",
                    pl: 1,
                    width: {
                      xl: "300px",
                      lg: "300px",
                      md: "300px",
                      sm: "200px",
                      xs: "200px",
                    },
                    textTransform: "capitalize",
                  }}
                  onClick={() => setOpenModel(true)}
                >
                  Send Message
                </Button>

                {
              isApkUpdated ? 
              <Button
              variant={"outlined"}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap:'10px',
                padding: "15px 20px",
                color: "#000000",
                margin: "15px",
                borderRadius: "6px",
                border: " 1px solid #000000",
                mt: 3,
                fontSize: "15px",
                pl: 1,
                width: "300px",
                textTransform: "capitalize",
              }}
              onClick={sendApkUpdate}
            >
             <img src='/images/loading-arrow.png' style={{height:'20px',width:'20px',objectFit:'cover'}}/> Update
            </Button>
            :
            <Button
            variant={"outlined"}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "15px 20px",
              color: "#000000",
              margin: "15px",
              borderRadius: "6px",
              border: " 1px solid #000000",
              mt: 3,
              fontSize: "15px",
              pl: 1,
              width: "300px",
              textTransform: "capitalize",
            }}
            disabled
          //   onClick={() => setOpenModel(true)}
          >
            Updated
          </Button>
            }


              </Box>
            </Box>
          </Grid>
        </Grid>
      </Layout>

      <Dialog
        // maxWidth={"xl"}
        fullWidth={true}
        open={openModel}
        onClose={() => setOpenModel(false)}
      >
        <DialogTitle
          style={{
            textAlign: "center",
            borderBottom: "2px solid var(--primary-color)",
            fontSize: "18px",
            fontWeight: 700,
          }}
        >
          Send Message
        </DialogTitle>
        <DialogContent>
          <div className="screen-form">
            <div className="icon-screen">
              {/* <AddScreenIcon /> */}
              <MailOutlineIcon
                sx={{
                  color: "var(--primary-color)",
                  height: "50px",
                  width: "50px",
                }}
              />
            </div>
            <div className="form-fields">
              <TextField
                type={"text"}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                variant={"outlined"}
                label={"Write Message"}
                multiline
                fullWidth
              />
            </div>

            <div className="form-fields">
              <TextField
                type={"number"}
                value={duration}
                onChange={(e) => setDuration(e.target.value)}
                sx={{
                  width: "100px",
                }}
              />
            </div>

            <Box
              sx={{
                display: "flex",
                mt: 8,
                mb: 5,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <button
                style={{
                  background: "white",
                  padding: "8px",
                  width: "130px",
                  color: "var(--primary-color)",
                  border: "1px solid var(--primary-color)",
                  marginRight: "10px",
                  borderRadius: "5px",
                  cursor: "pointer",
                }}
                onClick={() => setOpenModel(false)}
              >
                Cancel
              </button>
              <button
                style={{
                  background: "var(--primary-color)",
                  padding: "8px",
                  width: "130px",
                  color: "white",
                  border: "none",
                  borderRadius: "5px",
                  cursor: "pointer",
                }}
                onClick={submit}
                className="btn primary"
              >
                Send
              </button>
            </Box>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default Setting;
