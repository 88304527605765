import {
  Box,
  Button,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../../components/layout/Layout";
import { apkDelete, apkFlagOff, apkUpload, fetchApk } from "../../Redux/Actions/Apk";
// import { AiFillAndroid } from "react-icons/ai";
import {FaRegTrashAlt} from 'react-icons/fa';
import { AiFillAndroid } from "react-icons/ai";
import toast from "react-hot-toast";
import Loader from "../../components/loader/Loader";
// import Circularbar from "../../components/circularbar/Circularbar";
function ApkManage({user}) {
  const dispatch = useDispatch();
  const { apk, apkLoading, apkLoaded, isAvailable, isAdded,identity,isAdding } = useSelector(
    (state) => state.Apk
  );
  const [browseApk, setBrowseApk] = useState("");
  const [openSaveDialog, setOpenDialogSave] = useState(false);
  const [openDeleteDialog,setOpenDeleteDialog]=useState(false);
  const [deleteId,setDeleteId]=useState('');
  useEffect(() => {
    dispatch(fetchApk());
  }, []);
  useEffect(() => {
    if (isAvailable) {
      toast.success("This Apk already available");
      dispatch(apkFlagOff());
    }
    if (isAdded) {
      toast.success("Apk saved successfully");
      dispatch(apkFlagOff());
    }
    if(identity){
      toast.success("This apk not valid.");
      dispatch(apkFlagOff());
    }
  }, [isAvailable, isAdded,identity]);
  const handleUpload = (e) => {
      //  console.log(e)
      // if(e.target.files.length!==0){
    setBrowseApk(e);
    setOpenDialogSave(true);
      // }
  };
  const handleSave = () => {
    setOpenDialogSave(false);
    // console.log(browseApk)
    const form = new FormData();
    form.append("content", browseApk);
    setBrowseApk("");
    dispatch(apkUpload(form));
  };
  const handleCancel = () => {
    setBrowseApk("");
    setOpenDialogSave(false);
  };
  const handleDeleteDialog=(id)=>{
      setOpenDeleteDialog(true);
      setDeleteId(id);
  }
  const handleDelete=()=>{
    setOpenDeleteDialog(false);
    dispatch(apkDelete(deleteId));
  }
  return (
    <>
      <Layout title={"Apk Management"} user={user} playlistAddBtn={false}>
        <Container maxWidth={"lg"}>
          <Grid container mt={2} sx={{ backgroundColor: "#ffffff" }}>
            <Box
              sx={{
                height: "50vh",
                width: "100%",
                border: "1px solid white",
                borderRadius: "10px",
                // position:'relative'
              }}
            >
            {
              isAdding && <>
               <Box
        sx={{
          height:'100vh',
          width:'100vw',
          position:'absolute',
          top:0,
          left:0,
          bottom:0,
          right:0,
          zIndex:10000,
          background:'rgba(0,0,0,0.5)',
          display:'flex',
          justifyContent:'center',
          alignItems:'center'
        }}
        >
        <Loader/>
        </Box>
              </>
            }
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell colSpan={6}>
                          <input
                            type="file"
                            onChange={(e)=> handleUpload(e.target.files[0])}
                            hidden
                            id={"apk-brow"}
                          />
                          <label
                            style={{
                              display: "flex",
                              justifyContent: "flex-start",
                              alignItems: "center",
                            }}
                            
                          >
                            Browse{" "}
                            <AiFillAndroid
                              style={{
                                fontSize: "20px",
                                color: "#1977fa",
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                document.getElementById("apk-brow").click()
                              }
                            />{" "}
                            Apk File{" "}
                          </label>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center">Sr.#</TableCell>
                        <TableCell align="center">Upload Date</TableCell>
                        <TableCell align="center">Version Name</TableCell>
                        <TableCell align="center">Version Code</TableCell>
                        <TableCell align="center">Package</TableCell>
                        <TableCell align={'center'}>Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {apk.map((a, index) => (
                        <>
                          <TableRow>
                            <TableCell align={"center"}>{++index}</TableCell>
                            <TableCell align={'center'}>{new Date(a?.createdAt).toLocaleString()}</TableCell>
                            <TableCell align={"center"}>
                              {a?.versionName}
                            </TableCell>
                            <TableCell align={"center"}>
                              {a?.versionCode}
                            </TableCell>
                            <TableCell align={"center"}>{a?.package}</TableCell>
                            <TableCell align={'center'}><FaRegTrashAlt style={{
                              fontSize:'17px',
                                color:'red',
                                cursor: "pointer",
                              }}
                              onClick={()=>handleDeleteDialog(a?._id)}
                              /></TableCell>
                          </TableRow>
                        </>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Box>
          </Grid>
        </Container>
      </Layout>


      {/* <Dialog sx={{
        // maxWidth: '20vw',
        // height: '30vh'
        zIndex:'100000000'
      }} open={open} onClose={() => onClose(false)}>
        <DialogTitle sx={{
          fontWeight: '700',
          fontSize: '18px',
          borderBottom: '2px solid #551FFF',
          mb: 2,
          display: 'flex',
          justifyContent: 'center'
        }}>
          Delete
        </DialogTitle>
        <DialogContent>
          <Box
            sx={{
              display: "flex",
              gap: "2px",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography sx={{
              color: '#92959E'
            }}>Are you sure to delete this file?</Typography>
            <Box sx={{
              display: 'flex',
              mt: 5
            }}>
              <button style={{
                background: 'white',
                padding: '8px',
                width: '130px',
                color: '#551FFF',
                border: '1px solid #551FFF',
                marginRight: '10px',
                borderRadius: '5px'
              }} onClick={() => onClose(false)} className="btn danger">
                No
              </button>
              <button style={{
                background: '#551FFF',
                padding: '8px',
                width: '130px',
                color: 'white',
                border: 'none',
                borderRadius: '5px'
              }} onClick={onConfirm} className="btn primary">
                Yes
              </button>

            </Box>
          </Box>
        </DialogContent>
      </Dialog> */}


      <Dialog maxWidth={"lg"} open={openSaveDialog} onClose={handleCancel}>
        <DialogTitle
          sx={{
            fontWeight: '700',
          fontSize: '18px',
          borderBottom: '2px solid #551FFF',
          mb: 2,
          display: 'flex',
          justifyContent: 'center'
          }}
        >
          Save
         {/* <AiFillAndroid style={{fontSize:'30px',color:'#1977fa'}}/> Confirmation */}
        </DialogTitle>
        <DialogContent>
          <Box
            sx={{
              display: "flex",
              gap: "2px",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography sx={{ color: '#92959E' }}>
              Do you want to save?
            </Typography>
            <Box
              sx={{
                display: "flex",
                gap: "5px",
              }}
            >
              <Button
                variant={"outlined"}
                onClick={handleCancel}
                sx={{ width: "100px" }}
              >
                Cancel
              </Button>
              <Button
                variant={"contained"}
                onClick={handleSave}
                sx={{ width: "100px" }}
              >
                Save
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>

      <Dialog maxWidth={"lg"} open={openDeleteDialog} onClose={()=>setOpenDeleteDialog(true)}>
      <DialogTitle sx={{
          fontWeight: '700',
          fontSize: '18px',
          borderBottom: '2px solid #551FFF',
          mb: 2,
          display: 'flex',
          justifyContent: 'center'
        }}>
          Delete
        </DialogTitle>
        <DialogContent>
          <Box
            sx={{
              display: "flex",
              gap: "2px",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
             <Typography sx={{
              color: '#92959E'
            }}>Are you sure to delete this file?</Typography>
            <Box
              sx={{
                display: "flex",
                mt:5,
                gap: "5px",
              }}
            >
              <Button
                variant={"outlined"}
                onClick={()=>setOpenDeleteDialog(false)}
                sx={{ width: "100px" }}
              >
                No
              </Button>
              <Button
                variant={"contained"}
                onClick={handleDelete}
                sx={{ width: "100px" }}
              >
                Yes
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default ApkManage;
