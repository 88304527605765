import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Box,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../../components/layout/Layout";
import "./screen.css";
import {
  DeleteOutlineOutlined,
  DeleteOutlined,
  DesktopWindowsOutlined,
  DesktopMacOutlined,
  ModeEditOutlineOutlined,
} from "@mui/icons-material";
import SearchIcon from "@mui/icons-material/Search";
import DesktopMacIcon from "@mui/icons-material/DesktopMac";
import {
  deleteScreen,
  addScreen,
  getScreens,
  screenFlagOff,
  updateScreen,
} from "../../Redux/Actions/screenAction";
import { useNavigate } from "react-router-dom";
import TvIcon from "@mui/icons-material/Tv";
import { styled, alpha } from "@mui/material/styles";
import { ReactComponent as AddScreenIcon } from "../../Assets/images/addScreenIcon.svg";
import PrimarySearchBar from "../../components/searchBar/searchbar";
import DeleteModel from "../../components/deletemodel/DeleteModel";
import { getPlaylist } from "../../Redux/Actions/playlistAction";
import { getContent } from "../../Redux/Actions/contentAction";
import Loader from "../../components/loader/Loader";
import toast from "react-hot-toast";

const CustomTableCell = styled(TableCell)(({ theme }) => ({
  color: "#15192C",
  fontSize: "14px",
  fontWeight: "700",
}));

function Screen({ socketRef, user }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    screens,
    isScreenAdded,
    isAdded,
    isScreenDeleted,
    mac,
    screenLoading,
    isLoaded,
    isScreenUpdated,
  } = useSelector((state) => state.Screen);
  const [openModel, setOpenModel] = useState(false);
  const [nMac, setNMac] = useState("");
  const [screenId, setScreenId] = useState({ id: "", mac: "" });
  const [code, setCode] = useState("");
  const [name, setName] = useState("");
  const [orientation, setOrietation] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const macRef = useRef();
  const [textVal, setTextVal] = useState("");
  const [filteredScreen, setFilteredScreen] = useState([]);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [syncList, setSyncList] = useState([]);
  useEffect(() => {
    // dispatch(getScreens(user._id));
    socketRef.current.emit("leaving--connection");
    dispatch(getPlaylist(user?._id));
    dispatch(getScreens(user?._id));
    dispatch(getContent(user?._id));
  }, []);
  const _screens=useMemo(()=>[...screens],[isLoaded,isAdded,isScreenDeleted,isScreenUpdated])
  useEffect(() => {
    if (isAdded) {
      setFilteredScreen([..._screens]);
      dispatch(screenFlagOff());
    }
    if (isScreenDeleted) {
      setFilteredScreen([..._screens]);
      dispatch(screenFlagOff());
    }
    if (isScreenUpdated) {
      socketRef.current?.emit("event:delegate", { detail: { mac: mac } });
      dispatch(screenFlagOff());
      toast.success("Screen updated.");
    }
  }, [isAdded, isScreenDeleted, isScreenUpdated, mac]);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const emitOnDeleteScreen = (mac) => {
    const id = setTimeout(() => {
      socketRef.current?.emit("event:delegate", { detail: { mac: mac } });
      clearTimeout(id);
    }, 2000);
  };
  const deleteScreenHandler = (id, mac) => {
    setScreenId({ id: id, mac: mac });
    setOpenDeleteDialog(true);
  };
  const editScreenHandler = (id, mac) => {
    const _selectedScreen = _screens.find((screen) => screen._id == id);
    setName(_selectedScreen?.name);
    setCode(_selectedScreen?.code);
    setOrietation(_selectedScreen?.orientation);
    setScreenId({ id: id, mac: mac });
    setOpenEditDialog(true);
  };
  const confirmDeleteScreen = () => {
    dispatch(deleteScreen(screenId.id));
    emitOnDeleteScreen(screenId.mac);
    setOpenDeleteDialog(false);
  };
  const emitOnAddedScreen = () => {
    const id = setTimeout(() => {
      socketRef.current?.emit("event:delegate", {
        detail: { mac: macRef.current.value, code },
      });
      clearTimeout(id);
    }, 2000);
  };
  const submit = () => {
    dispatch(
      addScreen(
        {
          name,
          orientation,
          user: user?._id,
        },
        code
      )
    );
    setOpenModel(false);
    emitOnAddedScreen();
    // toast.success("Screen Added.");
    setCode("");
    setName("");
  };

  const onUpdate = () => {
    dispatch(
      updateScreen(
        {
          name,
          orientation,
        },
        screenId.id
      )
    );
    setOpenEditDialog(false);
    emitOnAddedScreen();
    // toast.success("Screen Added.");
    setCode("");
    setName("");
    setOrietation("");
  };

  const searchHandler = (e) => {
    setTextVal(e);
    if (e !== "") {
      const searched = _screens.filter((s) => {
        return Object.values(s)
          .join("")
          .toLocaleLowerCase()
          .includes(e.toLocaleLowerCase());
      });
      setFilteredScreen([...searched]);
    } else {
      setFilteredScreen([..._screens]);
    }
  };

  const onGetSyncList = (e) => {
    const { value, checked } = e.target;
    checked
      ? setSyncList((prev) => [...prev, value])
      : setSyncList(syncList.filter((_sync) => _sync !== value));
  };
  const onSyncScreen = () => {
    if (syncList.length > 0) {
      console.log(syncList);
      syncList.forEach(async (_sync, index) => {
        await socketRef.current.emit(
          "screen:sync",
          { detail: { mac: _sync } },
          function (done, _mac) {
            // if (done) {
            setSyncList((prevSyncList) =>
              prevSyncList.filter((mac) => mac !== _sync)
            );
            //}
          }
        );
      });
    }
  };

  function timeSince(date) {
    var seconds = Math.floor((new Date() - date) / 1000);

    var interval = seconds / 31536000;

    if (interval > 1) {
      return Math.floor(interval) + "y";
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      return Math.floor(interval) + "mon";
    }
    interval = seconds / 86400;
    if (interval > 1) {
      return Math.floor(interval) + "d";
    }
    interval = seconds / 3600;
    if (interval > 1) {
      return Math.floor(interval) + "h";
    }
    interval = seconds / 60;
    if (interval > 1) {
      return Math.floor(interval) + "min";
    }
    return Math.floor(seconds) + "sec";
  }

  return (
    <>
      <Layout title={"Screen"} user={user}>
        <input ref={macRef} hidden value={mac} />
        <div className="parent-container">
          <div className="child-container">
            <Grid container>
              <Box className="control-area">
                <Grid
                  item
                  lg={8.5}
                  sx={{
                    background: "white",
                    padding: "33px 0",
                    borderRadius: "16px",
                    mr: 2,
                  }}
                >
                  <PrimarySearchBar value={textVal} onChange={searchHandler} />
                </Grid>
                <Grid item lg={3.5}>
                  <div className="button-controlls flex flex-column">
                    <button
                      className="add-screen-btn"
                      onClick={() => setOpenModel(true)}
                    >
                      +Add Screen
                    </button>
                    <div className="lower-btn-group flex">
                      <button
                        className="btn-1 main-color"
                        onClick={onSyncScreen}
                      >
                        Sync Screens {syncList.length !== 0 && syncList.length}
                      </button>
                      <button className="btn-2 main-color">
                        Update all Screens
                      </button>
                    </div>
                  </div>
                </Grid>
              </Box>
            </Grid>
          </div>
          <div className="screen-table">
            <TableContainer
              component={Paper}
              sx={{ margin: "5px auto", borderRadius: "16px" }}
            >
              <Table>
                <TableHead>
                  <TableRow
                    style={{ borderBottom: "2px solid var(--primary-color)" }}
                  >
                    <CustomTableCell align="center"></CustomTableCell>
                    <CustomTableCell align="center">ID</CustomTableCell>
                    <CustomTableCell align="center">code</CustomTableCell>
                    <CustomTableCell align="center">Name</CustomTableCell>
                    <CustomTableCell align="center">Mac</CustomTableCell>
                    <CustomTableCell align="center">Software</CustomTableCell>
                    <CustomTableCell align="center">Playlist</CustomTableCell>
                    <CustomTableCell align="center">
                      Content Last Update
                    </CustomTableCell>
                    <CustomTableCell align="center">Status</CustomTableCell>
                    <CustomTableCell align="center">
                      Last Active
                    </CustomTableCell>
                    <CustomTableCell align="center">Actions</CustomTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {screenLoading && _screens.length==0 ? (
                    <Loader />
                  ) : filteredScreen.length > 0 ? (
                    filteredScreen
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((screen, index) => (
                        <>
                          <TableRow key={index}>
                            <TableCell align="left">
                              <input
                                type={"checkbox"}
                                style={{ height: "20px", width: "20px" }}
                                value={screen.mac}
                                onChange={onGetSyncList}
                              />
                            </TableCell>
                            <TableCell align="center">{++index}</TableCell>
                            <TableCell align="center">{screen.code}</TableCell>
                            <TableCell align="center">{screen.name}</TableCell>
                            <TableCell align="center">{screen.mac}</TableCell>
                            <TableCell align="center">{screen?.device_details?.software}</TableCell>
                            <TableCell align="center">
                              {screen?.playlist?.name}
                            </TableCell>
                            <TableCell align="center">
                              {screen?.lastUpdated !== undefined
                                ? `${timeSince(
                                    new Date(screen?.lastUpdated).getTime()
                                  )} ago`
                                : "N/A"}
                            </TableCell>
                            <TableCell align="center">
                              {screen.onlinesStatus ? (
                                <>
                                  <div className="status-badge success"></div>
                                </>
                              ) : (
                                <>
                                  <div className="status-badge danger"></div>
                                </>
                              )}
                            </TableCell>
                            <TableCell align="center">
                            {`${new Date(
                              screen?.lastActive
                            ).toDateString()} ${new Date(
                              screen?.lastActive
                            ).toLocaleTimeString()}`}
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                gap: "10px",
                              }}
                            >
                              <ModeEditOutlineOutlined
                                style={{ cursor: "pointer", marginTop: "-2px" }}
                                className="main-color"
                                onClick={() =>
                                  editScreenHandler(screen._id, screen.mac)
                                }
                              />
                              <DeleteOutlineOutlined
                                style={{ cursor: "pointer", marginTop: "-2px" }}
                                className="main-color"
                                onClick={() =>
                                  deleteScreenHandler(screen._id, screen.mac)
                                }
                              />

                              <DesktopWindowsOutlined
                                className="main-color"
                                height={20}
                                width={20}
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  navigate(`/setting/${screen._id}`)
                                }
                              />
                            </TableCell>
                          </TableRow>
                        </>
                      ))
                  ) : (
                    _screens
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((screen, index) => (
                        <>
                          <TableRow key={index}>
                            <TableCell align="left">
                              <input
                                type={"checkbox"}
                                style={{ height: "20px", width: "20px" }}
                                value={screen.mac}
                                onChange={onGetSyncList}
                              />
                            </TableCell>
                            <TableCell align="center">{++index}</TableCell>
                            <TableCell align="center">{screen.code}</TableCell>
                            <TableCell align="center">{screen.name}</TableCell>
                            <TableCell align="center">{screen.mac}</TableCell>
                            <TableCell align="center">{screen?.device_details?.software}</TableCell>
                            <TableCell align="center">
                              {screen?.playlist?.name}
                            </TableCell>
                            <TableCell align="center">
                              {" "}
                              {screen?.lastUpdated !== undefined
                                ? `${timeSince(
                                    new Date(screen?.lastUpdated).getTime()
                                  )} ago`
                                : "N/A"}
                            </TableCell>

                            <TableCell align="center">
                              {screen.onlinesStatus ? (
                                <>
                                  <div className="status-badge success"></div>
                                </>
                              ) : (
                                <>
                                  <div className="status-badge danger"></div>
                                </>
                              )}
                            </TableCell>
                            <TableCell align="center">{`${new Date(
                              screen?.lastActive
                            ).toDateString()} ${new Date(
                              screen?.lastActive
                            ).toLocaleTimeString()}`}</TableCell>
                            <TableCell
                              align="center"
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                gap: "10px",
                              }}
                            >
                              <ModeEditOutlineOutlined
                                style={{ cursor: "pointer", marginTop: "-2px" }}
                                className="main-color"
                                onClick={() =>
                                  editScreenHandler(screen._id, screen.mac)
                                }
                              />
                              <DeleteOutlineOutlined
                                style={{ cursor: "pointer", marginTop: "-2px" }}
                                className="main-color"
                                onClick={() =>
                                  deleteScreenHandler(screen._id, screen.mac)
                                }
                              />

                              <DesktopWindowsOutlined
                                className="main-color"
                                height={20}
                                width={20}
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  navigate(`/setting/${screen._id}`)
                                }
                              />
                            </TableCell>
                          </TableRow>
                        </>
                      ))
                  )}
                                       {screenLoading && _screens.length > 0 && <><Loader/></>}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[5, 10, 20]}
                component="div"
                style={{ display: "flex", justifyContent: "space-between" }}
                count={screens.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              ></TablePagination>
            </TableContainer>
          </div>
        </div>
      </Layout>

      <DeleteModel
        open={openDeleteDialog}
        onClose={(e) => setOpenDeleteDialog(e)}
        onConfirm={confirmDeleteScreen}
      />

      <Dialog
        // maxWidth={"sm"}
        fullWidth={true}
        open={openModel}
        onClose={() => setOpenModel(false)}
      >
        <DialogTitle
          style={{
            textAlign: "center",
            borderBottom: "2px solid var(--primary-color)",
            fontSize: "18px",
            fontWeight: 700,
          }}
        >
          Add Screen
        </DialogTitle>
        <DialogContent>
          <div className="screen-form">
            <div className="icon-screen">
              <DesktopMacOutlined
                sx={{ fontSize: "100px", color: "var(--primary-color)" }}
              />
            </div>
            <div className="form-fields">
              <TextField
                value={code}
                onChange={(e) => setCode(e.target.value)}
                type={"text"}
                variant={"standard"}
                label={"Type Screen Code"}
                fullWidth
                sx={{
                  label: {
                    color: "blue",
                    fontSize: "14px",
                    color: "#92959E",
                  },
                }}
              />
            </div>
            <div className="form-fields">
              <TextField
                value={name}
                onChange={(e) => setName(e.target.value)}
                type={"text"}
                variant={"standard"}
                label={"Type Screen Name"}
                fullWidth
                sx={{
                  label: {
                    color: "blue",
                    fontSize: "14px",
                    color: "#92959E",
                  },
                }}
              />
            </div>
            <div className="form-fields">
              <FormControl fullWidth variant={"standard"}>
                <InputLabel id={"orientation"}>Select Orientation</InputLabel>
                <Select
                  id={"orientation"}
                  onChange={(e) => setOrietation(e.target.value)}
                >
                  <MenuItem value="0">0°</MenuItem>
                  <MenuItem value="90">90°</MenuItem>
                  <MenuItem value="180">180°</MenuItem>
                  <MenuItem value="270">270°</MenuItem>
                </Select>
              </FormControl>
            </div>

            {/* <div className="add-user-form-submit-btn">
              <button style={{ marginTop: '40px' }} >Save</button>
            </div> */}

            <Box
              sx={{
                display: "flex",
                mt: 8,
                mb: 5,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <button
                className="btn-cancel-screen"
                onClick={() => setOpenModel(false)}
              >
                Cancel
              </button>
              <button onClick={submit} className="btn primary btn-save-screen">
                Save
              </button>
            </Box>
          </div>
        </DialogContent>
      </Dialog>

      <Dialog
        // maxWidth={"sm"}
        fullWidth={true}
        open={openEditDialog}
        onClose={() => setOpenEditDialog(false)}
      >
        <DialogTitle
          style={{
            textAlign: "center",
            borderBottom: "2px solid var(--primary-color)",
            fontSize: "18px",
            fontWeight: 700,
          }}
        >
          Edit Screen
        </DialogTitle>
        <DialogContent>
          <div className="screen-form">
            <div className="icon-screen">
              <DesktopMacOutlined
                sx={{ fontSize: "100px", color: "var(--primary-color)" }}
              />
            </div>
            <div className="form-fields">
              <TextField
                value={code}
                onChange={(e) => setCode(e.target.value)}
                disabled
                type={"text"}
                variant={"standard"}
                label={"Type Screen Code"}
                fullWidth
                sx={{
                  label: {
                    color: "blue",
                    fontSize: "14px",
                    color: "#92959E",
                  },
                }}
              />
            </div>
            <div className="form-fields">
              <TextField
                value={name}
                onChange={(e) => setName(e.target.value)}
                type={"text"}
                variant={"standard"}
                label={"Type Screen Name"}
                fullWidth
                sx={{
                  label: {
                    color: "blue",
                    fontSize: "14px",
                    color: "#92959E",
                  },
                }}
              />
            </div>
            <div className="form-fields">
              <FormControl fullWidth variant={"standard"}>
                <InputLabel id={"orientation"}>Select Orientation</InputLabel>
                <Select
                  id={"orientation"}
                  value={orientation}
                  onChange={(e) => setOrietation(e.target.value)}
                >
                  <MenuItem value="0">0°</MenuItem>
                  <MenuItem value="90">90°</MenuItem>
                  <MenuItem value="180">180°</MenuItem>
                  <MenuItem value="270">270°</MenuItem>
                </Select>
              </FormControl>
            </div>

            {/* <div className="add-user-form-submit-btn">
              <button style={{ marginTop: '40px' }} >Save</button>
            </div> */}

            <Box
              sx={{
                display: "flex",
                mt: 8,
                mb: 5,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <button
                style={{
                  background: "white",
                  padding: "8px",
                  width: "130px",
                  color: "var(--primary-color)",
                  border: "1px solid var(--primary-color)",
                  marginRight: "10px",
                  borderRadius: "5px",
                }}
                onClick={() => setOpenEditDialog(false)}
              >
                Cancel
              </button>
              <button
                style={{
                  background: "var(--primary-color)",
                  padding: "8px",
                  width: "130px",
                  color: "white",
                  border: "none",
                  borderRadius: "5px",
                }}
                onClick={onUpdate}
                className="btn primary"
              >
                Update
              </button>
            </Box>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default Screen;
