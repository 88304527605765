import React, { useCallback, useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  addScreenToPlaylist,
  deletePlaylist,
  playlistFlagOff,
  playlistScreenCheck,
  playlistToMultipleScreen,
} from "../../../../Redux/Actions/playlistAction";
import { getScreens } from "../../../../Redux/Actions/screenAction";
import "./playlistcard.css";
// import { ReactComponent as DeleteIcon } from "../../../../Assets/images/delete.svg";
// import { ReactComponent as CreateIcon } from "../../../../Assets/images/createicon.svg";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Menu,
  MenuItem,
  TextField,
} from "@mui/material";
import ScheduleSendIcon from "@mui/icons-material/ScheduleSend";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Close,
  DeleteOutlineOutlined,
  DesktopMacOutlined,
  PlaylistAddOutlined,
} from "@mui/icons-material";
import PlaylistContentCardItem from "../content-card/PlaylistContentCardItem";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { api } from "../../../../api/api";
import Loader from "../../../../components/loader/Loader";
import MiniLoader from "../../../../components/loader/MiniLoader";
import useFilter from "../../../../hooks/useFilter";

function PlaylistCard(props) {
  const dispatch = useDispatch();
  const { screens, isPlaylistAssign, isPlaylistUpdated, playlist, user } =
    useSelector(
      (state) => ({
        screens: state.Screen.screens,
        isPlaylistAssign: state.Playlist.isPlaylistAssign,
        isPlaylistUpdated: state.Playlist.isPlaylistUpdated,
        playlist: state.Playlist.playlist.find((p) => p._id == props.play._id),
        user: state.User.user,
      }),
      shallowEqual
    );
  const [code, setCode] = useState("");
  const [isScreenOpen, setIsScreenOpen] = useState(false);
  const [screenMacs, setScreenMacs] = useState([]);
  const [filteredScreenMacs, setFilterScreenMacs] = useState([]);
  const [unchecked, setUnchecked] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchVal, setSearchVal] = useState("");
  const open = Boolean(anchorEl);
  const filtered = useFilter(searchVal, screenMacs);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (type, obj) => {
    setAnchorEl(null);
    // setOpenProfileDialog(true);]
  };
  useEffect(() => {
    if (isPlaylistUpdated) {
      if (unchecked.length > 0) {
        for (let m of unchecked) {
          props.socketRef.current.emit("event:delegate", {
            detail: { mac: m },
          });
        }
        setUnchecked([]);
      }
      dispatch(getScreens(user?._id));
      setIsScreenOpen(false);
      _copyingScreens();
      //  dispatch(playlistFlagOff())
    }
  }, [isPlaylistUpdated]);
  useEffect(() => {
    dispatch(getScreens(user?._id));
    // console.log(props);
  }, []);
  useEffect(() => {
    _copyingScreens();
  }, []);
  const _copyingScreens = () => {
    let _screens = [];
    screens.forEach((s) => {
      _screens.push({
        _id: s._id,
        name: s.name,
        mac: s.mac,
        _playlistId: s?.playlist?._id,
        playlistName: s?.playlist?.name,
        isChecked: false,
      });
    });
    setScreenMacs(_screens);
  };
  // useEffect(()=>{
  //   setScreenMacs(filteredScreenMacs);
  // },[searchVal])
  // useEffect(()=>{
  //   let _macs=[]
  //  playlist.mac_list.forEach(m=>{
  //    _macs.push()
  //  })
  // },[props.play._id,isPlaylistAssign])
  const newCheck = (c) => {
    const id = setTimeout(() => {
      const screen = screens.find((s) => s._id === c);
      // console.log(screen);
      if (screen !== null) {
        props.socketRef.current?.emit("playlist_added", {
          detail: { mac: screen?.mac, code: c },
        });
        clearTimeout(id);
      }
    }, 2000);
  };
  const onGetScreenAssign = (e, position) => {
    const { value, checked, name } = e.target;
    // if(position=='unfiltered'){
    if (checked) {
      setScreenMacs((prev) => [
        ...prev.map((s) => (s.name == name ? { ...s, isChecked: true } : s)),
      ]);

      setUnchecked((prev) => [...prev.filter((m) => m !== value)]);
    } else {
      setScreenMacs((prev) => [
        ...prev.map((s) => (s.name == name ? { ...s, isChecked: false } : s)),
      ]);
      setUnchecked((prev) => [...prev, value]);
    }
    // }
  };
  const onAssignPlaylist = async () => {
    try {
      let mac_list = [];
      for (let i in filtered) {
        if (filtered[i].isChecked) {
          mac_list.push(filtered[i].mac);
        }
      }
      // console.log(filtered, mac_list)
      dispatch(playlistToMultipleScreen(props.play._id, mac_list, unchecked));
    } catch (error) {
      console.log(error.message);
    }
  };
  const onSearchFilter = (e) => {
    const { value } = e.target;
    setSearchVal(value);
  };
  const searchCallBack = useCallback(onSearchFilter, [searchVal]);

  const onOpenAssignScreen = (id) => {
    // console.log(screenMacs)
    const _checkedList = screenMacs.map((s) =>
      s._playlistId == id ? { ...s, isChecked: true } : s
    );
    // console.log('selected->',_checkedList)
    setScreenMacs(_checkedList);
    setIsScreenOpen(true);
  };
  return (
    <>
      {/* {console.log(filtered)} */}
      <div className="playlist-cards">
        <div className="playlist-cards-top">
          <div className="playlist-schedule-icon flex justifyContentCenter alignItemCenter"></div>
          <div className="playlist-card-body">
            {/* {props.children} */}
            {/* <DndProvider backend={HTML5Backend}> */}
            {props.play.content.map((v, index) => (
              <PlaylistContentCardItem
                key={index}
                content={v}
                deleteContent={props.deleteContent}
                addSchedule={props.addSchedule}
                updateTimeHandler={props.updateTimeHandler}
                playID={props.play._id}
              />
            ))}
            {/* </DndProvider> */}
          </div>
        </div>
        <div className="playlist-cards-bottom flex justify-content-space-between alignItemCenter">
          <div className="add-content-playlist flex justifyContentCenter alignItemCenter">
            <PlaylistAddOutlined
              style={{ color: "var(--primary-color)", cursor: "pointer" }}
              onClick={() =>
                props.addContentModel(
                  props.play._id,
                  props.play?.activescreen?.mac
                )
              }
            />
            <span>Create</span>
          </div>
          <Box>{props.play.name}</Box>
          <div className="delete-playlist flex justifyContentCenter alignItemCenter">
            {/* <DeleteOutlineOutlined
              onClick={() =>
                props.deleteWholePlaylist(
                  props.play._id,
                  props.play?.activescreen?.mac
                )
              }
              sx={{ color: "var(--primary-color)", cursor: "pointer" }}
            /> */}

            <MoreVertIcon
              sx={{ fontSize: "25px", color: "var(--primary-color)" }}
              onClick={handleClick}
              aria-controls={open ? "content-menu" : undefined}
              aria-haspopup={"true"}
              aria-expanded={open ? "true" : undefined}
              id="content-menu-btn"
            />
            <Menu
              sx={{ zIndex: "10000000" }}
              id={"content-menu"}
              anchorEl={anchorEl}
              open={open}
              MenuListProps={{
                "aria-labelledby": "content-menu-btn",
              }}
              onClose={handleClose}
              keepMounted
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <MenuItem
                onClick={() => {
                  handleClose();
                  props.deleteWholePlaylist(
                    props.play._id,
                    props.play?.activescreen?.mac
                  );
                }}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <DeleteOutlineOutlined
                  sx={{ color: "var(--primary-color)", cursor: "pointer" }}
                />
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleClose();
                  onOpenAssignScreen(props.play._id);
                  // props.addSchedule(props.playID, props.content._id);
                }}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <DesktopMacOutlined />
                {/* <ScheduleSendIcon
                  style={{
                    zIndex: "100000000",
                    color: "var(--primary-color)",
                  }}
                /> */}
              </MenuItem>
            </Menu>
          </div>
        </div>
      </div>

      <Dialog
        open={isScreenOpen}
        maxWidth={"sm"}
        onClose={() => setIsScreenOpen(false)}
        sx={{
          zIndex: "100000000000",
        }}
      >
        <DialogTitle display={"flex"} justifyContent={"space-between"}>
          <span>Assign to screens</span>{" "}
          <Close
            onClick={() => setIsScreenOpen(false)}
            sx={{ color: "red", cursor: "pointer" }}
          />{" "}
        </DialogTitle>
        <DialogContent>
          <Box
            sx={{
              height: "5vh",
              width: "100%",
              margin: "10px 0",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <TextField
              value={searchVal}
              onChange={searchCallBack}
              variant={"outlined"}
              label={"Search..."}
              fullWidth
            />
          </Box>
          <Box
            sx={{
              maxHeight: "40vh",
              width: "20dvw",
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              overflowY: "auto",
              // border:'1px solid black'
            }}
          >
            <Box
              sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Box
                display={"flex"}
                justifyContent={"flex-start"}
                alignItems={"center"}
                gap={"10px"}
              >
                <input
                  type={"checkbox"}
                  onChange={(e) =>
                    e.target.checked
                      ? setScreenMacs((prev) => [
                          ...prev.map((s) => ({ ...s, isChecked: true })),
                        ])
                      : setScreenMacs((prev) => [
                          ...prev.map((s) => ({ ...s, isChecked: false })),
                        ])
                  }
                  style={{ height: "20px", width: "20px" }}
                />
                <span>Select all</span>
              </Box>
              <Box>Playlist</Box>
            </Box>

            {/* <Box sx={{width:'100%',height:'100%',display:'flex',justifyContent:'space-evenly'}}> */}
            {filtered.map((_, _index) => (
              <>
                <Box
                  sx={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                  // display={"flex"}
                  // justifyContent={"flex-start"}
                  // alignItems={"center"}
                  // gap={"10px"}
                >
                  <Box
                    display={"flex"}
                    justifyContent={"flex-start"}
                    alignItems={"center"}
                    gap={"10px"}
                  >
                    <input
                      type={"checkbox"}
                      name={_?.name}
                      value={_?.mac}
                      onChange={(e) => onGetScreenAssign(e, "unfiltered")}
                      checked={_.isChecked}
                      // disabled={_?.playlistName!==undefined || _?.playlistName!=='' || _?.playlistName!==null ? _?.isChecked ? false : true : false}
                      style={{
                        height: "20px",
                        width: "20px",
                        cursor: "pointer",
                      }}
                    />
                    <span>{_?.name}</span>
                  </Box>
                  <span style={{ alignSelf: "flex-end" }}>
                    {_?.playlistName}
                  </span>
                </Box>
              </>
            ))}

            {/* </Box> */}
          </Box>
          <Box
            sx={{
              height: "10vh",
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Button
              onClick={() => {
                onAssignPlaylist();
              }}
              variant={"contained"}
              sx={{
                background: "var(--primary-color)",
                textTransform: "capitalize",
              }}
              className="multiple--assign--btn"
            >
              Assign
            </Button>
            {isPlaylistAssign && <MiniLoader />}
            {/* <span>Total Screens {screens.length}</span> */}
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default PlaylistCard;
