import { useEffect, useState } from "react"

const useFilter=(value,data)=>{
    const [filtered,setFiltered]=useState([]);
    useEffect(()=>{
      if(!value || value==''){
          setFiltered(data);
          return;
      }
      let searched=data.filter(f=>{
        return Object.values(f)
        .join('')
        .toLowerCase()
        .includes(value.toLowerCase())
      })
      setFiltered(searched);
    },[value,data])
    return filtered
}
export default useFilter;