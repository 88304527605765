import {
  Dialog,
  DialogContent,
  DialogTitle,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Paper,
  Grid,
  FormControl,
  InputAdornment,
  IconButton,
  Input,
  Typography,
  TablePagination
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../../components/layout/Layout";
import "./user.css";
import { deleteUser, getUsers, userFlagOff, userRegister } from "../../Redux/Actions/userAction";
import { Box } from "@mui/system";
import { styled, alpha } from '@mui/material/styles';
import { ReactComponent as DeleteIcon } from "../../Assets/images/delete.svg";
import { DeleteOutlineOutlined, SettingsRemoteOutlined, Visibility, VisibilityOff } from "@mui/icons-material";
import PrimarySearchBar from "../../components/searchBar/searchbar";
import {useForm} from 'react-hook-form';
import toast from 'react-hot-toast';
import DeleteModel from "../../components/deletemodel/DeleteModel";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/loader/Loader";
const CustomTableCell = styled(TableCell)(({ theme }) => ({
  color: '#15192C',
  fontSize: '14px',
  fontWeight: '700'
}));

function User({ user }) {
  const navigate=useNavigate();
  const [showPassword, setShowPassword] = React.useState(false);
  const {register,handleSubmit,reset,formState:{errors}}=useForm({
    mode:'onTouched'
  })
  const dispatch = useDispatch();
  const { isUserLoading, users,isUserAdded,isAlreadyAvailable,isUserDeleted } = useSelector((state) => state.User);
  const [openForm, setOpenForm] = useState(false);
  const [openDeleteModel,setOpenDeleteModel]=useState(false)
  const [userId,setUserId]=useState('');
  const [textVal,setTextVal]=useState('');
  const [filteredUser,setFilteredUser]=useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [role,setRole]=useState('user');
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const submitUser = (data) => {
    const {email,username,password}=data;
    dispatch(userRegister(
      {
        email,
        username,
        password,
        role:role,
        user:user._id
      }
    ))
    setOpenForm(false);
    reset({
      email:'',
      username:'',
      password:'',
    })
  }
  useEffect(()=>{
    dispatch(getUsers(user?._id))
  },[])
  useEffect(()=>{
    if(isUserAdded){
      toast.success('user added successfully');
      setFilteredUser([...users]);
      dispatch(userFlagOff());
    }
    if(isAlreadyAvailable){
      toast.error('User already registered');
      dispatch(userFlagOff());
    }
    if(isUserDeleted){
      toast.success('user deleted');
      setFilteredUser([...users]);
      dispatch(userFlagOff());
    }
  },[isUserAdded,isAlreadyAvailable,isUserDeleted])
  const askingForDelete=(id)=>{
    setUserId(id)
    setOpenDeleteModel(true)
  }
  const confirmDelete=()=>{
    dispatch(deleteUser(userId));
    setOpenDeleteModel(false)
  }
  const searchHandler=(e)=>{
     setTextVal(e);
     if(e!==''){
       const searched=users.filter(u=>{
         return Object.values(u)
         .join('')
         .toLocaleLowerCase()
         .includes(e.toLocaleLowerCase())
       })
       setFilteredUser([...searched]);
     }
     else{
       setFilteredUser([...users]);
     }
  }
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  return (
    <>
    {/* {
      console.log(users)
    } */}
      <Layout title={"User"} user={user}>
        <div className="parent-container">
          <div className="child-container">
            <Grid container>
              <Box className={'control-area'}>
                <Grid lg={8}>
                  <Box sx={{
                    background: 'white',
                    padding: '33px 0',
                    marginRight: '20px !important',
                    borderRadius: '16px',
                  }}>
                    <PrimarySearchBar
                    value={textVal}
                    onChange={searchHandler}
                    />
                  </Box>
                </Grid>
                <Grid lg={4} display={'flex'} justifyContent={'flex-end'}>
                  <div className="user-add-btn">
                    <button onClick={() => setOpenForm(true)}>+Add User</button>
                  </div>
                </Grid>
              </Box>
            </Grid>
          </div>

          <div className="table">
            <TableContainer component={Paper} sx={{
              borderRadius: '16px'
            }}>
              <Table className="table-background">
                <TableHead>
                  <TableRow style={{ borderBottom: "2px solid var(--primary-color)" }}>
                    <CustomTableCell align="center">ID</CustomTableCell>
                    <CustomTableCell align="center">Date</CustomTableCell>
                    <CustomTableCell align="center">Name</CustomTableCell>
                    <CustomTableCell align="center">Email</CustomTableCell>
                    <CustomTableCell align="center">Role</CustomTableCell>
                    <CustomTableCell align="center">Action</CustomTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {isUserLoading ? 
                    <>
                      <Loader/>
                    </>
                   :
                   filteredUser.length > 0 ? 
                   filteredUser  .slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  ).map((row, index) => (
                    <>
                      <TableRow>
                        <TableCell align="center">{++index}</TableCell>
                        <TableCell align="center">{new Date(row.createdAt).toLocaleString()}</TableCell>
                        <TableCell align="center">{row.username}</TableCell>
                        <TableCell align="center">{row.email}</TableCell>
                        <TableCell align="center">{row.role}</TableCell>
                        <TableCell align="center">
                        <Visibility onClick={()=>navigate(`/user-panel/${row._id}`)}
                        
                          style={{cursor:'pointer',margin:'-3px 5px',color:"var(--primary-color)"}}
                          />
                          <DeleteOutlineOutlined fontSize={'medium'}
                          onClick={()=> askingForDelete(row._id) } sx={{
                    
                    color:'var(--primary-color)'
                  }}></DeleteOutlineOutlined></TableCell>
                      </TableRow>
                    </>
                  )
                )  
                   :
                   users.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  ).map((row, index) => (
                    <>
                      <TableRow>
                        <TableCell align="center">{++index}</TableCell>
                        <TableCell align="center">{new Date(row.createdAt).toLocaleString()}</TableCell>
                        <TableCell align="center">{row.username}</TableCell>
                        <TableCell align="center">{row.email}</TableCell>
                        <TableCell align="center">{row.role}</TableCell>
                        <TableCell align="center">
                        <Visibility onClick={()=>navigate(`/user-panel/${row._id}`)}
                          sx={{cursor:'pointer',margin:'-3px 5px',color:"var(--primary-color)"}}
                          />
                          <DeleteOutlineOutlined fontSize={'medium'} onClick={()=> askingForDelete(row._id) } sx={{
                         
                    color:"var(--primary-color)"
                  }}></DeleteOutlineOutlined></TableCell>
                      </TableRow>
                    </>
                  )
                )
                    }
                 
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[5, 10, 20]}
                component="div"
                style={{ display: "flex", justifyContent: "space-between" }}
                count={user.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              ></TablePagination>
            </TableContainer>
          </div>
        </div>

         <DeleteModel
         open={openDeleteModel}
         onClose={(e)=>setOpenDeleteModel(e)}
         onConfirm={confirmDelete}
         />

        <Dialog
          // maxWidth={'xl'}
          open={openForm}
          onClose={() => setOpenForm(false)}
          sx={{
            "& .MuiPaper-root-MuiDialog-paper": {
              borderRadius: '16px'
            }
          }}
          className="dialogBorder"
        >
          <DialogTitle style={{
            textAlign: 'center', borderBottom: '2px solid var(--primary-color)', fontSize: '18px',
            fontWeight: 700
          }}>Add User</DialogTitle>
          <DialogContent sx={{
            pb: 5
          }}>
            <form onSubmit={handleSubmit(submitUser)}>
            <div className="user-add-form-container">
              <div className="form-fields">
                <TextField
                  {
                    ...register('username',{
                      required:true
                    })
                  }
                  type={'text'}
                  variant={'standard'}
                  label={'Name*'}
                  fullWidth
                />
                <Typography>{errors.username && 'username is required'}</Typography>
              </div>
              <div className="form-fields">
                <TextField
                  {
                    ...register('email',{
                      required:true
                    })
                  }
                  type={'email'}
                  variant={'standard'}
                  label={'forexample@gmail.com'}
                  fullWidth
                />
                <Typography>{errors.email && 'email is required'}</Typography>
              </div>
              <div className="form-fields">
                <FormControl sx={{ width: '100%' }} variant="standard">
                  <InputLabel htmlFor="standard-adornment-password">Password*</InputLabel>
                  <Input
                  {
                    ...register('password',{
                      required:true
                    })
                  }
                    id="standard-adornment-password"
                    type={showPassword ? 'text' : 'password'}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <VisibilityOff sx={{
                            color: "#551FFF",
                          }} /> : <Visibility sx={{
                            color: "#551FFF",
                          }} />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
                <Typography>{errors.password && 'password is required'}</Typography>
              </div>

              <div className="form-fields">
                <FormControl variant={'standard'} fullWidth>
                <InputLabel id="role-id">Role</InputLabel>
                <Input
                 labelId={'role-id'}
                type={'text'}
                readOnly
                disabled
                value={role}
                onChange={(e)=>setRole(e.target.value)}
                
                />
                </FormControl>
                {/* <Select
                  variant={'standard'}
                  labelId="role-id"
                  {
                    ...register(
                      'role',
                      {
                        required:true
                      }
                    )
                  }
                  fullWidth
                >
                  {/* <MenuItem value='admin'>Admin</MenuItem> 
                  <MenuItem value='user'>User</MenuItem>
                </Select> */}
                {/* <Typography>{errors.role && 'role is required'}</Typography> */}
              </div>

              <div className="add-user-form-submit-btn">
                <button type={'submit'}>Add</button>
              </div>
            </div>
            </form>
          </DialogContent>
        </Dialog>
      </Layout>
    </>
  );
}

export default User;
