import {
  Checkbox,
  Container,
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  TextField,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./login.css";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useDispatch, useSelector } from "react-redux";
import { login, userFlagOff } from "../../Redux/Actions/userAction";
import { Box } from "@mui/system";
import loginBackgroundIMG from "../../Assets/images/lexi-logo.jpeg";
import Cookies from "js-cookie";
import toast from "react-hot-toast";
import axios from "axios";
import Loader from "../../components/loader/Loader";
import CustomCheckbox from "../../components/utils/CustomCheckbox";
function Login() {
  const dispatch = useDispatch();
  const { auth, loginSuccess, failed, isLogging } = useSelector(
    (state) => state.User
  );
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [type, setType] = useState(true);
  const [rememberme, setRememberMe] = useState(false);

  useEffect(() => {
    if (loginSuccess) {
      toast.success("login success!");
      dispatch(userFlagOff());
    }
    if (failed) {
      toast.error("login failed!");
      dispatch(userFlagOff());
    }
  }, [loginSuccess, failed]);
  useEffect(() => {
    // socketRef.current.emit('leaving--connection')
    const isOk = Cookies.get("leedoitCredential");
    if (isOk !== undefined) {
      const d = JSON.parse(Cookies.get("leedoitCredential"));
      setEmail(d?.email);
      setPassword(d?.password);
      setRememberMe(true);
    } else {
      setPassword("");
      setEmail("");
      setRememberMe(false);
    }
  }, []);
  const submitLogin = () => {
    if (email === "") {
      return;
    }
    if (password === "") {
      return;
    }
    dispatch(login(email, password));
  };
  const checkedRememberMe = (e) => {
    const { checked } = e.target;
    if (checked) {
      setRememberMe(true);
      Cookies.set(
        "leedoitCredential",
        JSON.stringify({ email: email, password: password }),
        { expires: 7 }
      );
    } else {
      Cookies.remove("leedoitCredential");
      setRememberMe(false);
    }
  };
  if (auth) {
    return navigate("/");
  }
  return (
    <>
      <Box
        sx={{
          minHeight: "100vh",
          maxWidth: "100vw",
          display: "flex",
          position: "relative",
          margin: 0,
          padding: 0,
          boxSizing: "border-box",
        }}
      >
        {isLogging && (
          <>
            <Box
              sx={{
                height: "100vh",
                width: "100vw",
                position: "absolute",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                background: "rgba(0,0,0,0.5)",
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                zIndex: "100000",
              }}
            >
              <Loader />
            </Box>
          </>
        )}

        <Box
          sx={{
            height: "100dvh",
            width: "70dvw",
            // border: "1px solid black",
            backgroundImage: `url(${loginBackgroundIMG})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "contain",
            display: { lg: "block", md: "block", sm: "none", xs: "none" },
          }}
        ></Box>
        <Box
          sx={{
            height: "100dvh",
            width: {
              xl: "30dvw",
              lg: "30dvw",
              md: "30dvw",
              sm: "100dvw",
              xs: "100dvw",
            },
            backgroundImage: {
              xl: "none",
              lg: "none",
              md: "none",
              sm: "none",
              xs: "none",
            },
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "cover",
            // border: "1px solid black",
          }}
        >
          <Container maxWidth="xl">
            <Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100vh",
                  position: "relative",
                }}
              >
                <Box
                  sx={{
                    width: { lg: "100%", md: "100%", sm: "99%", xs: "99%" },
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    // border:'1px solid black'
                  }}
                >
                  <div className="login-form">
                    <div className="login-form-header">
                      <div className="logo-sec">
                        <Box
                          sx={{
                            height: {
                              xl: "120px",
                              lg: "120px",
                              md: "120px",
                              sm: "70px",
                              xs: "70px",
                            },
                            width: {
                              xl: "120px",
                              lg: "120px",
                              md: "120px",
                              sm: "70px",
                              xs: "70px",
                            },
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <img
                            src="/images/lexi-logo.jpeg"
                            style={{ height: "100%", width: "100%",objectFit:'contain' }}
                          />
                        </Box>
                      </div>
                    </div>
                    <div className="login-form-body">
                      <div className="title">
                        <div className="login-icon"></div>
                        <Box
                          sx={{
                            height: "15%",
                            width: "100%",
                            display: "flex",
                            margin: "20px 0",
                            fontFamily: "'Open Sans', sans-serif",
                            fontStyle: "normal",
                            fontWeight: 700,
                            lineHeight: "54px",
                            letterSpacing: "-0.02em",
                            color: "#313031",
                            marginTop: "-7px",
                            marginLeft: "10px",
                            fontSize: {
                              xl: "40px",
                              lg: "40px",
                              md: "40px",
                              sm: "25px",
                              xs: "25px",
                            },
                          }}
                        >
                          Login
                        </Box>
                      </div>
                      <div className="input-fields">
                        <TextField
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          type={"email"}
                          variant={"standard"}
                          label={"Email"}
                          style={{
                            borderBottom: "1px solid #551FFF",
                            color: "#551FFF",
                          }}
                          fullWidth
                        />
                      </div>
                      <div className="input-fields">
                        <FormControl variant={"standard"} fullWidth>
                          <InputLabel htmlFor="password">Password</InputLabel>
                          <Input
                            id="password"
                            type={type ? "password" : "text"}
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            endAdornment={
                              <InputAdornment
                                position={"end"}
                                style={{
                                  marginTop: "-10px",
                                  color: "var(--primary-color)",
                                }}
                              >
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={() => setType(!type)}
                                >
                                  {type ? (
                                    <VisibilityOffIcon
                                      style={{ color: "var(--primary-color)" }}
                                    />
                                  ) : (
                                    <VisibilityIcon
                                      style={{ color: "var(--primary-color)" }}
                                    />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            }
                          />
                        </FormControl>
                      </div>
                      <div className="other-controlls">
                        <div className="checkbox-div">
                          {/* <Checkbox
                            checked={rememberme}
                            onChange={checkedRememberMe}
                            style={{
                              margin: "-10px 0",
                              borderRadius: "4px",
                            }}
                          />{" "} */}
                          <CustomCheckbox
                          rememberme={rememberme}
                          checkedRememberMe={checkedRememberMe}
                          /> {" "}
                          Remember me
                        </div>
                        <Link to={"/forgetpassword"}>Forget Password?</Link>
                      </div>
                      <div className="button-field">
                        <button className="btn-login" onClick={submitLogin}>
                          Login
                        </button>
                      </div>
                    </div>
                  </div>
                </Box>
                {/* <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    width: { lg: "50%", md: "50%", sm: "0%", xs: "0%" },
                  }}
                >
                  <div className="text-block">
                    <p className="main-heading">
                      We Make Your Business Digitalize
                    </p>
                    <p className="sub-heading">
                      If you have question please contact
                      support@sns-groupe.com?
                    </p>
                  </div>
                </Box> */}
              </Box>
              <div className="bottom-box"></div>
            </Box>
          </Container>
        </Box>
      </Box>
    </>
  );
}

export default Login;
