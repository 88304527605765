import React ,{useState} from 'react'
import PhotoCameraBackIcon from '@mui/icons-material/PhotoCameraBack';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import {AppsOutlined} from '@mui/icons-material'
import { imgUrl } from '../../../api/api';
import ScheduleSendIcon from "@mui/icons-material/ScheduleSend";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Menu, MenuItem } from '@mui/material';
function Widget({ data,addSchedule, deleteSingleContent }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
 
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (type, obj) => {
    setAnchorEl(null);
  };
  return (
    <>
      <div className="content-item" key={data.key}>
        <div className="content-image">
          <div className="content-del-icon">
          <DeleteOutlineIcon  onClick={() => deleteSingleContent(data.id)} style={{ color: 'var(--primary-color)' }} />
          </div>
          <img src={`${data.widget.widgetIcon}`} style={{height:'100%',width:'100%',objectFit:'contain'}} alt="" />
        </div>
        <div className="content-bottom">
          <p>{data.name?.substring(0,20)}</p>
          <p style={{
            display: 'flex',
            alignItems: 'center'
          }}>
            <span>
              <AppsOutlined sx={{
                fontSize: '17px',
                color:"var(--primary-color)"
              }} />
            </span>
            <span>{data.type}</span>
          </p>
        </div>
      </div>
    </>
  )
}

export default Widget