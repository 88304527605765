import React, { useCallback, useEffect, useState } from "react";
import { imgUrl } from "../../../../api/api";
import { Menu, MenuItem } from "@mui/material";
import ScheduleSendIcon from "@mui/icons-material/ScheduleSend";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { DeleteOutlineOutlined, LanguageOutlined } from "@mui/icons-material";
import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";
import { useDispatch, useSelector } from "react-redux";
import {
  playlistContentSort,
  playlistFlagOff,
} from "../../../../Redux/Actions/playlistAction";
// import { ReactComponent as DeleteIcon } from "../../../../Assets/images/delete.svg";
function PlaylistContentCardItem(props) {
  const { isSorted } = useSelector((state) => state.Playlist);
  const [pageNumber, setPageNumber] = useState(1);
  const [anchorEl, setAnchorEl] = useState(null);
  const ref = React.useRef(null);
  const [startIndex, setStartIndex] = useState("");
  const [lastIndex, setLastIndex] = useState("");
  const dispatch = useDispatch();
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (type, obj) => {
    setAnchorEl(null);
  };

  const handleSorting = (e) => {
    const _first = e.dataTransfer.getData("firstIndex");
    dispatch(
      playlistContentSort({
        playlistId: props.playID,
        from: _first,
        to: lastIndex,
      })
    );

    //toast.success("Playlist sorted successfully.");
  };
  const FirstIndex = (e, id) => {
    e.stopPropagation();
    e.dataTransfer.setData("firstIndex", id);
    //setStartIndex(id)
  };
  const LastIndex = (e, id) => {
    e.preventDefault();
    setLastIndex(id);
    // setLastIndex(id)
  };
  return (
    <>
      {/* <div className="content-card-item">
        
     </div> */}
      <>
        <div
          className="content-card-item"
          draggable
          onDragStart={(e) => FirstIndex(e, props.content._id)}
          onDragEnter={(e) => {
            e.stopPropagation();
            //  LastIndex(props.content._id)
          }}
          onDragOver={(e) => LastIndex(e, props.content._id)}
          // onDragEnd={(e)=>}
          onDrop={(e) => handleSorting(e)}
        >
          <div className="content-card-item-body">
            <div className="upper-sec flex justify-content-space-between">
              <div
                // onClick={()=>alert('hello')}
                onClick={() =>
                  props.updateTimeHandler(
                    props.playID,
                    props.content._id,
                    props.content.duration
                    // play?.activescreen?.mac
                  )
                }
                className={`${
                  "timer-background"
                } timer flex justifyContentCenter alignItemCenter`}
              >
                {/* {props.content.type !== "video" && ( */}
                  <>
                    <AccessTimeIcon
                      sx={{
                        marginRight: "5px",
                        // zIndex: "1000000000000",
                      }}
                    />
                    <span>
                      {Math.floor((props.content?.duration / 1000 / 60) << 0)}:
                      {Math.floor((props.content?.duration / 1000) % 60)}
                    </span>
                  </>
                {/* )} */}
              </div>

              <div className="delete-icon flex justifyContentCenter alignItemCenter">
                <MoreVertIcon
                  sx={{ fontSize: "25px", color: "var(--primary-color)" }}
                  onClick={handleClick}
                  aria-controls={open ? "content-menu" : undefined}
                  aria-haspopup={"true"}
                  aria-expanded={open ? "true" : undefined}
                  id="content-menu-btn"
                />
                <Menu
                  sx={{ zIndex: "10000000" }}
                  id={"content-menu"}
                  anchorEl={anchorEl}
                  open={open}
                  MenuListProps={{
                    "aria-labelledby": "content-menu-btn",
                  }}
                  onClose={handleClose}
                  keepMounted
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      props.deleteContent(
                        props.playID,
                        props.content._id
                        // play?.activescreen?._id
                      );
                    }}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <DeleteOutlineOutlined
                      sx={{ color: "var(--primary-color)" }}
                    />
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      props.addSchedule(props.playID, props.content._id);
                    }}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <ScheduleSendIcon
                      style={{
                        zIndex: "100000000",
                        color: "var(--primary-color)",
                      }}
                    />
                  </MenuItem>
                </Menu>
              </div>
            </div>
            {props.content.type === "image" && (
              <img src={`${props.content.url}`} alt="" />
            )}
            {props.content.type === "video" && (
              <video src={`${props.content.url}`} controls />
            )}
            {props.content.type === "url" && (
              <div
                style={{
                  height: "200px",
                  width: "200px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "relative",
                }}
              >
                <LanguageOutlined
                  sx={{
                    fontSize: "17px",
                    height: "60%",
                    width: "60%",
                    // color:"var(--primary-color)"
                  }}
                />
              </div>
            )}
            {
              props.content.type === "application" && (
                <div
                  style={{
                    height: "200px",
                    width: "200px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "relative",
                  }}
                >
                  <PictureAsPdfOutlinedIcon
                    sx={{
                      height: "60%",
                      width: "60%",
                    }}
                  />
                  <div
                    style={{
                      position: "absolute",
                      height: "30px",
                      width: "150px",
                      background: "rgba(255,0,16,.2)",
                      borderRadius: "5px",
                      bottom: 10,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <span>{props.content?.name?.substring(0, 16)}</span>
                  </div>
                </div>
              )
              // <Document
              // file={`${imgUrl}/${props.content?.url}`}
              //   onLoadError={(error) => console.log("failed=", error)}
              //   onLoadSuccess={({ numPages }) => setPageNumber(numPages)}
              // >
              //   <Page  pageNumber={pageNumber}
              //     height={'130'}
              //     width={'130'}
              //     renderTextLayer={false}
              //     renderAnnotationLayer={false}  />
              // </Document>
            }
             {
              props.content.type === "widget" && (
                <div
                  style={{
                    height: "200px",
                    width: "200px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "relative",
                  }}
                >
                  <img
                  src={`${props.content?.widgetIcon}`}
                    style={{
                      height: "60%",
                      width: "60%",
                    }}
                  />
                  <div
                    style={{
                      position: "absolute",
                      height: "30px",
                      width: "150px",
                      background: "rgba(255,0,16,.2)",
                      borderRadius: "5px",
                      bottom: 10,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <span>{props.content?.name?.substring(0, 16)}</span>
                  </div>
                </div>
              )
            }
          </div>
        </div>
      </>
    </>
  );
}

export default PlaylistContentCardItem;
